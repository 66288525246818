var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "padd15", staticStyle: { "overflow-y": "auto" } },
    [
      _c(
        "div",
        [
          _c("h1", [_vm._v(_vm._s(_vm.getHeadTitle || "客服") + " ")]),
          _vm.rightData && _vm.rightData.name == "header"
            ? _c(
                "div",
                [
                  _c("el-divider"),
                  _c(
                    "el-form",
                    {
                      attrs: { inline: true },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "页面名称", required: "" } },
                        [
                          _c("el-input", {
                            on: { change: _vm.templatehead },
                            model: {
                              value: _vm.rightData.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.rightData, "title", $$v)
                              },
                              expression: "rightData.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(_vm.rightData.name, {
            tag: "component",
            attrs: { keys: "" + new Date(), data: _vm.rightData },
            on: { setTemp: _vm.setTemp },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }